<!--章节管理-->
<template>
    <div>
        <a-row class="searchBox">
            <a-space class="flex flex-wrap">
                <a-select ref="select" v-model:value="param.grade_id" style="width: 150px" :allowClear="true" placeholder="年级" @change="handleSelectChange">
                    <a-select-option v-for="(item,idx) in seletArray.grade" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="param.term_id" style="width: 150px" :allowClear="true" placeholder="学期" @change="handleSelectChange">
                    <a-select-option v-for="(item,idx) in seletArray.term" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="param.subject_id" style="width: 150px" :allowClear="true" placeholder="科目" @change="handleSelectChange">
                    <a-select-option v-for="(item,idx) in seletArray.subject" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-button type="primary" @click="handleEdit()">
                    <PlusCircleOutlined />添加章节
                </a-button>
            </a-space>
        </a-row>
        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" bordered :pagination="false" :scroll="{ y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #knowledge_count="{text, record, index}">
                    <div class="flex flex-ai-c flex-jc-sb" style="width:100%">
                        <span>{{text}}</span>
                        <icon-font type="icon-chakanliebiao" style="font-size:24px" @click="JumpKnow(record)" />
                    </div>
                </template>
                <template #operation="{ record }">
                    <a-space>
                        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                        <a-button type="primary" @click="handleDelete(record)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>

        <!--添加章节-->
        <ConfigChapterEdit ref="ConfigChapterEdit" :seletArray="seletArray" @success="getList"></ConfigChapterEdit>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted, createVNode } from 'vue';
import TablePagination from '@/components/TablePagination';
import ConfigChapterEdit from '@/components/ConfigChapterEdit';
import useDelete from '@/hooks/useDelete.js';
import { PlusCircleOutlined } from '@ant-design/icons-vue';

export default defineComponent({
    components: {
        TablePagination,
        ConfigChapterEdit,
        PlusCircleOutlined
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const { useDeleteFun } = useDelete();
        const ConfigChapterEdit = ref();
        onMounted(() => {
            TypeClass();
            getList()
        });
        const initData = reactive({
            seletArray: {},
            list: [],

            columns: [
                { title: '序号', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '章节名称', dataIndex: 'name' },
                // { title: '学科类别', dataIndex: 'cate.name' },
                { title: '年级', dataIndex: 'grade.name' },
                { title: '学期', dataIndex: 'term.name' },
                { title: '科目', dataIndex: 'subject.name' },
                { title: '知识点', dataIndex: 'knowledge_count', slots: { customRender: 'knowledge_count' } },
                { title: '录入时间', dataIndex: 'create_time' },
                { title: '操作', fixed: 'right', width: 180, slots: { customRender: 'operation' } },
            ],
            param: {
                curr: 1,
                row: 10
            },
            Form: {},
            title: '添加章节',
            count: 0,
            pages: 1
        });
        const TypeClass = () => {
            proxy.http.post('/admin.chapter/config').then(response => {
                initData.seletArray = response;
            })
        }

        const getList = () => {
            proxy.http.post('/admin.chapter/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };
        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };
        //新建弹框
        const handleEdit = (row) => {
            let { grade_id,term_id, subject_id, name} = row ? row : initData.param;
            ConfigChapterEdit.value.title = row ? '编辑章节' : '添加章节';
            ConfigChapterEdit.value.form = row ? {
                id: row.id,
                cate_id:1,
                grade_id,
                subject_id,
                name,
                term_id
            } : {
                cate_id:1,
                grade_id,
                subject_id,
                term_id
            };
            ConfigChapterEdit.value.handleDrawer(true);
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.chapter/delete', [row.id]).then(() => {
                getList();
            })
        }

        const handleSelectChange = () => {
            initData.list = [];
            initData.param.curr = 1;
            getList();
        };
        const JumpKnow = (row) => {
            proxy.http.go('/ConfigKnowledge', { chapterId: row.id,gradeId:row.grade_id,termId:row.term_id,subjectId:row.subject_id })
        };

        return {
            ...toRefs(initData),
            getList,
            TypeClass,
            ConfigChapterEdit,
            changeTableRequest,
            handleEdit,
            handleDelete,
            handleSelectChange,
            JumpKnow
        }
    }
})
</script>
